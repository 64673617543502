/* Top-level shared styles */
/* You can keep styles that are shared by different components here */

html {
  height: 100%;
}

body {
  color: darkslategrey;
  background-color: white;
  margin: 40px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}
